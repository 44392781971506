.degree {
    /*Fresno State Colors*/
    /*red*/
    --Pantone186: #c41230;
    /*blue*/
    --Pantone2767: #13284c;
    /*Cool Gray*/
    --PantoneCoolGray7: #98989a;
    /*Green V*/
    --Pantone356: #007935;
}

.degree td {
    /* color: var(--Pantone186); */
    border-collapse: initial;
}

.degree a {
    color: white;
}

td#honors {
    font-style: italic;
}

.degree img {
    height: 10vh;
    width: 10vw;
    object-fit: scale-down;
}

#school a {
    color: white;
    text-decoration: none;
}

#school a:hover {
    color: var(--Pantone2767);
    background-color: white;
}

table.csuf {
    /* background-color: var(--PantoneCoolGray7); */
    border-collapse: collapse;
    border: 1px solid var(--PantoneCoolGray7);
    margin: 2vh auto;
}

td.diploma {
    text-align: center;
    background-color: white;
}

.diploma a {
    color: var(--Pantone186);
    /* color: var(--Pantone2767); */
    text-decoration: none;
}

td.bulldogLogo {
    background-color: var(--Pantone186);
}

.csuf td.info {
    background-color: var(--Pantone2767);
    font-size: 1.25rem;
    /* text-shadow: 1px 1px 1px rgba(152, 152, 154, 1); */
    /* text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5); */
    /* text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5); */
    padding: 1vh 1vw;
    color: white;
}

.csuf caption {
    display: none;
}