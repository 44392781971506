html {

    --Color4: #CED1D6;
    --Color3: #707EA0;
    --Color2: #314676;
    --Color1: #0B1F4D;
    --Color0: #010B22;

    /* background-color: white; */
    height: 100%;

}

/*Ensures content of each page reaches bottom.  Works with "flex-direction" in #root.*/
div#Projects,
div#education,
div#Skills,
div#WorkExperience {
    flex: 1;
}

body {
    min-height: 100%;
}

#root {
    /* background-color: blue; */
    /* margin-bottom: 7vh; */
    /* height: 100%; */
    position: absolute;
    /* top: 0; */
    /* bottom: 0; */
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;
    /* background-image: linear-gradient(white, var(--Color2)); */
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

caption {
    text-align: left;
}

td {
    text-align: center;
}

a {
    color: darkgreen
}

img.pdfIcon {
    height: 5vh;
    width: 5vw;
}

p {
    margin: 0 2vw;
}

h1 {
    font-size: 5vh;
}

h3 {
    font-size: 2.5vh;
}

h4 {
    margin: 1vh 0 0.5vh 0;
    color: var(--Purple0);
}

/*Desktop*/
@media only screen and (min-width:768px) {
    .section {
        scroll-margin-top: 8vh;
        padding: 2vh 0 2vh 0;
    }
}

/*Mobile*/
@media only screen and (max-width:768px) {
    .section {
        scroll-margin-top: 2.75rem;
        padding: 2vh 0 2vh 0;
    }
}

.pageh1 {
    margin: 0 0;
    text-align: center;
    font-size: 2rem;
    color: var(--Color0);
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}