/*Cards CSS*/

.Cards-Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* border: 1px solid #ccc; */
    /* background-color: black; */
}

.card {
    width: 300px;
    height: auto;
    border: 1px solid black;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

/* .card content h1 {
    font-size: 2.5rem;
} */

.card img {
    width: 100%;
    height: auto;
    margin: 0;
    display: block;
    /* border: 1px solid black; */
}

.card-content {
    margin: 0;
    padding: 0 0 1vh 0;
    border-top: 1px solid black;
    display: flex;
    flex-direction: column;
}

.card-title {
    margin: 1vh 0 0 0;
    text-align: center;
    /* border: 1px solid #ccc; */
    color: var(--Color0);
}

h1.card-title {
    font-size: 1.5rem;
}

.card-description {
    color: var(--Color0);
    font-size: 1rem;
    margin: 0 0;
    justify-content: center;
    /* border: 1px solid #ccc; */
    display: flex;
    /* overflow: hidden; */
}

.card-description li {
    margin: 1vh 0;
}

.card-links {
    justify-content: center;
    display: flex;
    margin: 0;
    /* border: 1px solid #ccc; */
    /* overflow: hidden; */
}

.card-links img {
    width: auto;
}

.card-links .ytIcon {
    height: 3.5vh;
    margin: 0 1vw;
}

.card-links .webAppIcon {
    height: 3.5vh;
    margin: 0 1vw;
}

.card-links .gitHubMark {
    height: 3.5vh;
    margin: 0 1vw;
}