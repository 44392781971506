/* Skills Page CSS*/

.SkillsTables {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#Skills H4 {
    color: var(--Color0);
}

#Skills table {
    border: 1px solid black;
    /* background-color: blue; */
    /* float: left; */
    /* border-spacing: 0.1rem 0px; */
    border-collapse: collapse;
    /* display: inline-table; */
    margin: 2vh 2vw;
    /* position: relative; */
}

#Skills table td,
#Skills table th {
    /* border: 1px solid var(--Color2); */
    padding: 1vh 1vw;
}

#Skills table th {
    border-bottom: 1px solid black;
}

#Skills li.odd {
    background-color: var(--Odd);
    color: var(--Color0);
}

#Skills li.even {
    background-color: var(--Color4);
    color: var(--Color0);
}

#Skills li {
    padding: 0 1vw;
}

/*To move ul to top of table cells*/
#Skills tr {
    vertical-align: center;
}

#Skills tr.odd {
    background-color: var(--Color4);
    color: var(--Color0);
}

#Skills tr.even {
    background-color: var(--Even);
    color: var(--Color0);
}

#Skills th {
    text-align: center;
}