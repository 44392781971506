#loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--Color0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: var(--Color4);
    opacity: 1;
    transition: opacity 4s ease-out;
    text-align: center;
}

#loading-screen.fade-out {
    opacity: 0;
    z-index: 0;
}

#loading-screen h1 {
    font-size: 4rem;
    text-align: center;
    margin: 1vh 0;
}

#loading-screen h2 {
    font-size: 2rem;
}

#loading-screen h3 {
    font-size: 1.5rem;
}

#loading-screen .spinner {
    margin: 10vh 0 1vh 0;
    width: 4rem;
    height: 4rem;
    border: 4px solid var(--Color3);
    border-top: 4px solid var(--Color2);
    border-radius: 50%;
    animation: spin 4s linear infinite;
    /* background-color: var(--Color1); */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}