nav {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1rem;
    top: 0;
    position: sticky;
}

nav ul {
    text-align: center;
    display: inline;
}

nav .NavLink-Active li {
    /* color: black; */
    color: var(--Color0);
    background-color: var(--Color4);
    background-image: none;
    /* border: 1px solid var(--Color0); */
    /* text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); */
}

.NavLink li:hover {
    /* color: black;
    background-color: white; */
    color: var(--Color0);
    background-color: var(--Color4);
    /* text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); */
    background-image: none;
}

nav a {
    text-decoration: none;
}

nav li {
    /* color: white; */
    color: var(--Color4);
    /* text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); */
    /* background-image: linear-gradient(white, var(--DarkBlue), white); */
    background-color: var(--Color0);
    padding: 1vh 1vw;
    border-radius: 50px;
    display: inline-block;
}