:root {
    --contactIconHeight: 7vh;
    --contactIconWidth: 7vw;
}

img#emailIcon {
    height: var(--contactIconHeight);
    /* width: var(--contactIconWidth); */
}

img#phoneIcon {
    height: var(--contactIconHeight);
    /* width: var(--contactIconWidth); */
}

img#gitHubMark {
    height: var(--contactIconHeight);
    /* width: var(--contactIconWidth); */
}

img#linkedInLogo {
    height: var(--contactIconHeight);
}

img#resumeIcon {
    height: var(--contactIconHeight);
    /* width: var(--contactIconWidth); */
}

table#contact {
    margin: auto auto;
    /* border-spacing: 3vh; */
    /* border-collapse: separate; */

}

table#contact td {
    padding: 0vh 4vw;
}

table#contact thead {
    display: none;
}

table#contact caption {
    display: none;
}