footer {
    /* position: sticky; */
    /* bottom: 0; */
    /* background-color: var(--DarkBlue); */
    /* background-image: linear-gradient(white, var(--DarkBlue)); */
    /* background-color: var(--Color3); */
    background-image: linear-gradient(var(--Color4), var(--Color0));
    width: 100%;
    /* margin: 0; */
    flex-shrink: 0;
}

/*Google Maps */
/* iframe#location {
    height: 15vh;
    width: 15vw;
    border: 0;
    float: right;
} */