#Work_Experience caption h4 {
    color: var(--Color0);
    font-size: 1.5rem;
}

#Work_Experience td {
    padding: 1vh 0;
}

#Work_Experience tr.odd {
    /* background-color: var(--Color4); */
    color: var(--Color0);
}

#Work_Experience tr.even {
    background-color: var(--Color4);
    color: var(--Color0);
}

#Work_Experience thead {
    display: none;
}

#Work_Experience td.description {
    text-align: left;
    /* padding: 0 2vw 1vh 0; */
}

#Work_Experience table {
    border-collapse: collapse;
    margin: 2vh auto;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

/*Desktop*/
@media only screen and (min-width: 768px) {
    #Work_Experience table {
        width: 90vw;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    #Work_Experience table {
        margin: 2vh auto;
        border-collapse: collapse;
        font-size: 1rem;
    }
}

/*Debug tool*/
/* 
@media only screen and (orientation: landscape) {
    #Work_Experience table {
        font-size: 1rem;
    }
} */


/* #Work_Experience td.start {
    width: 10%;
    padding-right: 1vw;
}

#Work_Experience .end {
    width: 10%;
}

#Work_Experience .title {
    width: 30%;
}

#Work_Experience .company {
    width: 30%;
}

#Work_Experience .location {
    width: 20%;
} */


/* table.WorkExperience tr {
    margin: 2vh;
}*/