.header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* background-image: linear-gradient(45deg, white, var(--DarkBlue)); */
    background-image: linear-gradient(45deg, var(--Color0), var(--Color4));
    /* background-color: var(--Blue0); */
    position: sticky;
    top: 0;
    align-items: center;
    z-index: 500;
    /* overflow: visible; */
    /* height: 50px; */

}

#headerHeadings {
    text-align: center;
    color: var(--Color4);
    text-shadow: 1px 1px 4px rgba(0, 0, 0, .5);
}

/*Desktop*/
@media only screen and (min-width: 768px) {
    #headerHeadings h1 {
        font-size: 3rem;
        margin: 0;
    }

    #headerHeadings h3 {
        font-size: 1.5rem;
        margin: 0;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    #headerHeadings h1 {
        font-size: 1.5rem;
        margin: 0;
    }

    #headerHeadings h3 {
        font-size: 1rem;
        margin: 0;
    }
}