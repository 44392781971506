#Projects {
    /* margin: 4vh auto; */
    /* padding: 0; */
    /* width: 90vw; */
    /* max-width: 95vw; */
    /* background-image: url('../Assets/Classroom.jpeg');
    background-size: cover;
    background-position: center; */
    /* background-color: var(--Section0); */
    /* background-image: linear-gradient(var(--Color3), var(--Color1)); */

}

#Projects h2 {
    /* background-color: black; */
    /* color: var(--H2); */
}

/* #projectsCaption {
    color: #6c757d;
    padding-top: .5rem;
    padding-bottom: .5rem;
} */

#Projects p {
    text-align: center;
    margin: 1vh;
}